/**
 * Created by ApolloLogix on 2022-02-25.
 */

/*
<li id="forms/contact" class="nav-item " onclick="alert(&quot;yeah stop&quot;)">
        <a class="nav-link waves-effect " href="http://local.marblebar.vagrant/forms/contact" target="_self">
			Contact        </a>
    </li>

    //subscribe button
    <a href="" class="btn btn-primary waves-effect waves-effect" data-toggle="modal" data-target="#modal_sub_login_reg" style="">Subscribe</a>
 */

function set_innertext_id(id, text, html = false) {
    if (html) {
        document.getElementById(id).innerHTML = text;
    } else {
        document.getElementById(id).innerText = text;

    }
}

function set_innertext_class(class_name, text, html = false) {
    const elements = document.getElementsByClassName(class_name);
    for (let i = 0; i < elements.length; i++) {
        if (html) {
            elements[i].innerHTML = text;
        } else {
            elements[i].innerText = text;
        }
    }
}

function reset_form(form_id) {
    document.getElementById(form_id).reset();
}

const menu_item_contact = document.getElementById("forms/contact");

console.log("menu_item_contact:" + menu_item_contact);
//turn menu button contact in a modal show
if (menu_item_contact) {
    menu_item_contact.childNodes[1].setAttribute('href', '#')
    menu_item_contact.childNodes[1].setAttribute('data-toggle', 'modal')
    menu_item_contact.childNodes[1].setAttribute('data-target', '#modal_sub_login_reg')
}

const menu_item_site_edit = document.getElementById("Admin_tools/site_edit");
if (menu_item_site_edit) {
    menu_item_site_edit.childNodes[1].setAttribute('href', '#')
    menu_item_site_edit.childNodes[1].setAttribute('data-toggle', 'modal')
    menu_item_site_edit.childNodes[1].setAttribute('data-target', '#modal_site_editor')
}

//var currentPanel = "<?php echo $this->data['site_data']['forms']['contact']['contact']['panel_id']?>";
//shared/js/libraries/mdbootstrap/_intro-mdb-pro.js:90
var showForm = true;

/*
forms.json : contact/contact/panel_id
//http://local.marblebar.vagrant/forms/contact#newsletter_subscribe
//http://local.marblebar.vagrant/forms/contact#contact
currentPanel = 'newsletter_subscribe' or 'contact'
//jQuery to show model and the panel
$('#modal_sub_login_reg').modal('show');
$('.nav-tabs a[href="#' + currentPanel + '"]').tab('show');
 */

if (menu_item_contact) {
    menu_item_contact.onclick = function () {
        console.log("menu_item_contact clicked");
        const currentPanel = "contact";
        $('.nav-tabs a[href="#' + currentPanel + '"]').tab('show');
        // clear the form
        //document.getElementById("contact_form").reset(); // this works
        //reset_form("contact_form");
        // clear elements with class "flash_message"
        set_innertext_class("flash_message", "");
    };
}

// subscribe button
//<a href="" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modal_sub_login_reg" style="">Subscribe</a>
const btn_subscribe = document.getElementById("subscribe");
if (btn_subscribe) {
    btn_subscribe.onclick = function () {
        console.log("btn_subscribe clicked");
        var currentPanel = "newsletter_subscribe";
        $('.nav-tabs a[href="#' + currentPanel + '"]').tab('show');
        // clear elements with class "flash_message"
        set_innertext_class("flash_message", "");
    };
}

/*
ideas:
maybe preload pages and hide them until clicked on menu
 */

// site editor button
const btn_site_editor_save = document.getElementById("btn_site_editor_save");
if (btn_site_editor_save) {
    btn_site_editor_save.onclick = function () {
        console.log("btn_site_editor_save clicked");


        //use var colors as key and rgb_colors as value
        var colors_dict = {}
        for (let i = 0; i < colors.length; i++) {
            colors_dict[colors[i]] = rgb_color[i];
        }
        console.log('colors_dict:', colors_dict)

        var jsonData = JSON.stringify(colors_dict);
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "/data/save_json_data/site_edit/", true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(jsonData);
        xhr.onload = function () {
            if (xhr.status === 200) {
                console.log("Data saved successfully.");
            } else {
                console.log("Error saving data.");
            }
        };

        //close the modal
        $('#modal_site_editor').modal('hide');

    };
}

// contact from submit button
document.getElementById("contact_form").addEventListener("submit", function (event) {
    event.preventDefault(); // Prevent the default behavior of the form submit event

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/forms/contact/true", true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    // Define a function to handle the response from the server
    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            // Parse the response from the server as JSON
            const response = JSON.parse(xhr.responseText);
            console.log('response:', response);
            console.log('response.success:', response.success);
            console.log('response.message:', response.message);

            console.log('response.data:', response.data);
            console.log('response.data.form_errors:', response.data.form_errors);
            const form_errors = response.data.form_errors;
            const fields = response.data.fields;


            if (response !== null) {
                console.log('response:', response);

                // Check if the email was sent successfully
                if (response.success) {
                    // Show an alert with the success message
                } else {
                    // Show an alert with an error message
                }

                // clear the fields
                if (fields) {
                    console.log('fields:', fields);
                    console.log('Object.entries(fields):', Object.entries(fields));
                    console.log('Object.entries(fields).length:', Object.entries(fields).length);
                    for (const [key, value] of Object.entries(fields)) {
                        console.log('key, value:', key, value);
                        //document.getElementById(key).value = value;
                        set_innertext_id(key, value);
                    }
                    // clear fields with class "flash_message"
                    set_innertext_class("flash_message", "");
                }

                // display the form_errors
                console.log('form_errors:', form_errors);
                if (form_errors) {
                    console.log('Object.entries(form_errors):', Object.entries(form_errors));
                    for (const [key, value] of Object.entries(form_errors)) {
                        console.log('key, value:', key, value);
                        //document.getElementById(key).innerHTML = value;
                        //document.getElementById(key).innerHTML = value;
                        //document.getElementById('contact_form[message]_block_id').innerHTML = 'hofofododo'
                        //Uncaught TypeError: Cannot set properties of null (setting 'innerHTML')
                        //document.getElementById(key).innerHTML = value;
                        set_innertext_id(key, value, true);
                    }
                }

                // show message in the form at elements with class="flash_message"
                if (response.message) {
                    const flash_messages = document.getElementsByClassName("flash_message");
                    for (let i = 0; i < flash_messages.length; i++) {
                        flash_messages[i].innerHTML = response.message;
                    }
                }

            }

        }
    };

    // Send the form data as the request body
    xhr.send(new URLSearchParams(new FormData(this)).toString());
});

